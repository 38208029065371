import { Component, Injectable, InjectionToken, Injector, Type, inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, filter } from 'rxjs';
import { QueuingTooEarlyModalComponent } from 'clients/nexus-main/src/app/event/queuing/queuing-too-early-modal/queuing-too-early-modal.component';

export interface ModalConfig {
  template: typeof QueuingTooEarlyModalComponent;
  injector: Injector,
  heading?: string;
  data?: any;
  fullscreenOnMobile?: boolean;
  size?: 'sm'|'lg'|'xl';
  dangerous?: boolean;
}

export interface ModalRef {
  close: (data?: any) => void;
  afterClosed: () => Observable<any>;
}

export const MODAL_DATA = new InjectionToken<any>('modal_data');
export const MODAL_REF = new InjectionToken<any>('modal_ref');

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private readonly injector = inject(Injector);
  private readonly router = inject(Router);

  readonly modal$ = new BehaviorSubject<ModalConfig | undefined>(undefined);
  afterClosed$?: Subject<any>;

  constructor() {
    this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe(() => {
      this.close();
    });
  }

  open(template: Type<any>, heading: string, config: Partial<ModalConfig> = {}): ModalRef {
    this.afterClosed$?.complete();
    this.afterClosed$ = new Subject<any>();

    const ref: ModalRef = {
      close: (data?: any) => this.close(data),
      afterClosed: () => this.afterClosed$!.asObservable(),
    };
    const modalInjector = Injector.create({
      providers: [
        { provide: MODAL_DATA, useValue: config.data },
        { provide: MODAL_REF, useValue: ref },
      ],
      parent: this.injector,
    });

    this.modal$.next({
      ...config,
      heading,
      template,
      injector: modalInjector,
    });

    return ref;
  }

  close(data?: any) {
    if (this.modal$.value) {
      this.modal$.next(undefined);
      this.afterClosed$?.next(data);
      this.afterClosed$?.complete();
    }
  }

  getModal() {
    return this.modal$.asObservable();
  }
}
